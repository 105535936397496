import React, {useEffect, useState} from 'react';
import content from 'commons/content.json';
import {dpW, dpH} from 'commons';
import FlatList from 'component/flatlist';
import {View, TextLabel} from 'component';
import {
  Image,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import * as Api from 'service-agent';

const tabs = [
  {id: 0, title: 'New Release'},
  {id: 1, title: 'Popular Songs'},
  {id: 2, title: 'For You'},
  {id: 3, title: 'Shared Playlist'},
  {id: 4, title: 'Your Playlist'},
  {id: 5, title: 'Last Played'},
  {id: 6, title: 'Your Favorite'},
];

const sid = '4d06d262-33e2-555d-b72f-1ee33c8c8500';

const SongItem = ({item}) => {
  const {artist_picture_file, title, artist_name} = item;
  return (
    <View width={dpW(49)} height={dpH(22)} marginR={5} bg="#212121">
      <Image
        source={{uri: artist_picture_file.box_small}}
        style={{width: '100%', height: '71%'}}
      />
      <View padding={8}>
        <TextLabel small text={title} />
        <TextLabel xxsmall text={artist_name} color="darkcyan" />
      </View>
    </View>
  );
};

const Library = () => {
  const [song, setSong] = useState(null);
  const [auth, setAuth] = useState(false);
  const [tophit, setTopHit] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [foryou, setForYou] = useState(null);
  const [select, setSelect] = useState(0);

  let data = null;

  useEffect(() => {
    Api.songGet
      .newest()
      .then(setSong)
      .catch(onError);
    return () => {};
  }, []);

  const {title} = content.home.library;

  const onError = () => {};
  const getTophit = e => {
    if (e === null) {
      Api.songGet
        .tophit()
        .then(setTopHit)
        .catch(onError);
    }
  };
  const getForYou = e => {
    if (e === null) {
      Api.songGet
        .recommand(sid)
        .then(setForYou)
        .catch(onError);
    }
  };

  const getPlaylist = e => {
    if (e === null && auth) {
      Api.songGet
        .playlist()
        .then(setPlaylist)
        .catch(onError);
    }
  };

  const getSongs = e => {
    if (e === 1) {
      getTophit(tophit);
    } else if (e === 2) {
      getForYou(foryou);
    } else if (e === 3) {
      getPlaylist(playlist);
    }
  };

  const renderItem = ({item, index}) => {
    return <SongItem item={item} />;
  };

  const renderTab = (v, i) => {
    const selected = select === i;
    const onPress = () => {
      setSelect(i);
      getSongs(i);
    };
    return (
      <TouchableOpacity onPress={onPress} key={i.toString()}>
        <View marginH={dpW(2)} maxWidth={dpW(30)} marginL={i === 3 ? 50 : 10}>
          <TextLabel
            xsmall
            text={v.title}
            color={selected ? 'white' : '#aaa'}
            fontWeight={selected ? 'bold' : undefined}
          />
          {selected && (
            <View marginT={8} width={'45%'} height={1} bg={'orange'} />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  if (select === 0) {
    data = song;
  } else if (select === 1) {
    data = tophit;
  } else if (select === 2) {
    data = foryou;
  } else if (select === 3) {
    data = playlist;
  }

  const onLogin = () => {};

  return (
    <View flex marginV={dpH(2)} paddingT={dpH(2)}>
      <View>
        <View centerH paddingH={dpW(8)}>
          <TextLabel large text={title} line={3} align="center" />
          <View height={1} bg={'darkcyan'} width={dpW(20)} marginT={dpH(1)} />
        </View>
      </View>
      <ScrollView horizontal>
        <View row marginB={dpH(2)} marginT={dpH(5)} paddingH={dpW(1)}>
          {tabs.map(renderTab)}
        </View>
      </ScrollView>
      {data === null ? (
        <View centerH centerV marginV={dpH(3)} height={dpH(25)}>
          {select > 2 && !auth ? (
            <TouchableOpacity onPress={onLogin}>
              <View
                br={5}
                centerH
                marginV={dpH(1.5)}
                width={dpW(30)}
                bg={'rgba(37,126,184,1)'}
                paddingH={dpW(1)}
                paddingV={dpH(0.5)}>
                <TextLabel xsmall text="Need Login" />
              </View>
            </TouchableOpacity>
          ) : (
            <ActivityIndicator size="large" color="white" />
          )}
        </View>
      ) : (
        <FlatList
          song={data}
          renderItem={renderItem}
          setMore={e => setSong(e)}
        />
      )}
    </View>
  );
};

export default Library;
