import React from 'react';
import {Text} from 'react-native';
import {dpH} from 'commons';

const f10 = dpH('1.2%');
const f12 = dpH('1.5%');
const f14 = dpH('1.7%');
const f16 = dpH('1.95%');
const f18 = dpH('2.2%');
const f24 = dpH('2.9%');
const f32 = dpH('3.9%');
const f48 = dpH('5.8%');

const TextLabel = res => {
  const {refs, shadowColor, children, others} = res;
  const stylesArray = [];
  if (res.rubikL) {
    stylesArray.push({fontFamily: 'Rubik-Light'});
  } else if (res.rubikR) {
    stylesArray.push({fontFamily: 'Rubik-Regular'});
  } else if (res.rubikB) {
    stylesArray.push({fontFamily: 'Rubik-Bold'});
  } else if (res.rubikBl) {
    stylesArray.push({fontFamily: 'Rubik-Black'});
  } else if (res.rubikM) {
    stylesArray.push({fontFamily: 'Rubik-Medium'});
  } else if (res.robotoB) {
    stylesArray.push({fontFamily: 'Roboto-Bold'});
  } else if (res.robotoM) {
    stylesArray.push({fontFamily: 'Roboto-Medium'});
  } else if (res.robotoR) {
    stylesArray.push({fontFamily: 'Roboto-Regular'});
  } else if (res.monospace) {
    stylesArray.push({fontFamily: 'monospace'});
  } else {
    stylesArray.push({fontFamily: 'Roboto-Regular'});
  }

  if (res.italic) {
    stylesArray.push({fontStyle: 'italic'});
  }

  if (res.base) {
    stylesArray.push({fontSize: f18});
  } else if (res.badge) {
    stylesArray.push({fontSize: f10});
  } else if (res.xxsmall) {
    stylesArray.push({fontSize: f12});
  } else if (res.xsmall) {
    stylesArray.push({fontSize: f14});
  } else if (res.small) {
    stylesArray.push({fontSize: f16});
  } else if (res.medium) {
    stylesArray.push({fontSize: f24});
  } else if (res.large) {
    stylesArray.push({fontSize: f32});
  } else if (res.xlarge) {
    stylesArray.push({fontSize: f48});
  }

 
  stylesArray.push({
    textShadowOffset: {width: 0, height: 1},
    textShadowRadius: 1,
    textShadowColor: shadowColor,
    textTransform: res.transform,
    marginRight: res.mr,
    marginLeft: res.ml,
    marginTop: res.mt,
    marginBottom: res.mb,
    textAlign: res.align,
    color: res.color,
    fontFamily: 'Nunito',
    fontWeight: res.fontWeight,
    //fontSize: f18,
  });
  stylesArray.push(res.style);

  return (
    <Text
      nativeID={res.nativeID}
      // badge={res.badge}
      // xsmall={res.xsmall}
      // small={res.small}
      // base={res.base}
      // normal={res.normal}
      // medium={res.medium}
      // mediumx={res.mediumx}
      // large={res.large}
      // xlarge={res.xlarge}
      // xxlarge={res.xxlarge}
      // color={res.color}
      style={stylesArray}
      numberOfLines={res.line}
      allowFontScaling={false}
      ref={refs}
      {...others}>
      {res.text}
      {children}
    </Text>
  );
};

TextLabel.defaultProps = {
 // small:true,
  line: 1,
  color: '#bbb',
  shadowColor: 'rgba(0, 0, 0, 0.4)',
  mt: 0,
  mb: 0,
  ml: 0,
  mr: 0,
};

export default TextLabel;
