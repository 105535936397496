module.exports = {
  get dpH() {
    return require('./PercentToDp').dpH;
  },
  get dpW() {
    return require('./PercentToDp').dpW;
  },
  get BANNER() {
    return require('./banner.json');
  },
};
