import React, {useState} from 'react';
import {
  TextInput,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native';
import {View, TextLabel} from 'component';
import {dpW, dpH} from 'commons';
//import * as Api from 'service-agent';

const WARN = '#f5ea92';

export default function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState({value: '', valid: false});
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  let emailRev;
  let nameRev;
  let phoneRev;
  let subjectRev;
  let messageRev;

  const validateEmail = text => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      setEmail({value: text, valid: false});
      return false;
    } else {
      setEmail(text);
      setEmail({value: text, valid: true});
    }
  };

  const getValidation = e => {
    let isValid = true;
    const backgroundColor = WARN;
    if (!e.name) {
      nameRev.focus();
      nameRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!email.valid) {
      emailRev.focus();
      emailRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!e.phone) {
      phoneRev.focus();
      phoneRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!e.subject) {
      subjectRev.focus();
      subjectRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!e.message) {
      messageRev.focus();
      messageRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = e => {
    const msg = `${message}\n\n${name}\n${phone}`;
    const param = {
      name: name,
      email: email.value,
      phone: phone,
      subject: subject,
      message: msg,
    };
    console.log('onSubmit', param);
    const isValid = getValidation(param);
    const requestOptions = {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify(param),
    };
    const send = async e => {
      const response = await fetch(e, requestOptions);
      const resp = await response.json();
      if (resp) {
        setSuccess(true);
      }
    };
    if (isValid) {
      send('https://web.smart-karaoke.com/contact/index.php');
    }
  };

  const formInput = (
    <View paddingH={dpH(1)} marginT={dpH(5)}>
      <TextInput
        style={st.input}
        placeholder="Your Name *"
        onChangeText={text => setName(text)}
        ref={e => (nameRev = e)}
        value={name}
      />
      <TextInput
        style={st.input}
        placeholder="Your Email *"
        onChangeText={text => validateEmail(text)}
        ref={e => (emailRev = e)}
        value={email.value}
      />
      <TextInput
        style={st.input}
        maxLength={18}
        keyboardType="numeric"
        placeholder="Your Phone Number *"
        onChangeText={text => setPhone(text)}
        ref={e => (phoneRev = e)}
        value={phone}
      />
      <TextInput
        style={st.input}
        placeholder="Subject *"
        onChangeText={text => setSubject(text)}
        ref={e => (subjectRev = e)}
        value={subject}
      />
      <TextInput
        style={st.textArea}
        ref={e => (messageRev = e)}
        onChangeText={text => setMessage(text)}
        placeholder={'Your Message *'}
        numberOfLines={10}
        multiline={true}
      />
      <View centerH>
        <TouchableOpacity onPress={onSubmit}>
          <View
            br={5}
            centerH
            marginV={dpH(1.5)}
            width={dpW(35)}
            bg={'rgba(37,126,184,1)'}
            paddingH={dpW(1)}
            paddingV={dpH(0.5)}>
            <TextLabel small text="Send Message" />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );

  const sendSuccess = (
    <View paddingH={dpH(1)} marginV={dpH(5)} centerH>
      <Image
        source={require('assets/check-mark.png')}
        style={{
          width: dpH(10),
          height: dpH(10),
          tintColor: 'darkcyan',
          marginVertical: dpH(3),
        }}
      />
      <TextLabel
        mt={dpH(5)}
        base
        text="Your Message has been send"
        color="orange"
      />
      <TextLabel mt={dpH(1)} small text="Thank you" />
    </View>
  );

  return (
    <View flex marginV={dpH(2)}>
      <View centerH>
        <Image
          source={{uri: 'https://image.ibb.co/kUagtU/rocket_contact.png'}}
          style={{
            width: dpH(6),
            height: dpH(6),
            borderRadius: dpH(6) / 2,
            alignSelf: 'center',
          }}
        />
        <TextLabel mt={dpH(2)} large text="Drop us a Message" align="center" />
        <View height={1} bg={'darkcyan'} width={dpW(15)} marginT={dpH(1)} />
      </View>
      {success ? sendSuccess : formInput}
    </View>
  );
}

const st = StyleSheet.create({
  input: {
    height: dpH(5.5),
    marginVertical: 3,
    borderRadius: 5,
    width: '100%',
    borderColor: 'gray',
    borderWidth: 1,
    backgroundColor: '#bbb',
    fontSize: dpH(1.6),
    color: 'black',
    paddingHorizontal: 10,
  },
  textArea: {
    width: '100%',
    height: dpH(18),
    marginVertical: 3,
    borderWidth: 1,
    borderColor: '#9E9E9E',
    borderRadius: 5,
    backgroundColor: '#ccc',
    padding: 10,
    fontSize: dpH(1.6),
  },
});
