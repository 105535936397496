import React from 'react';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
//import {Navigation} from './Navigation';
//import {Home} from './Screen/Home';
import Main from './Screen/Main';
import TopBar from 'component/TopBar';
//import BottomTab from 'component/BottomTab'
//import {NotFound} from './Screen/NotFound';
//import {Vehicule} from './Screen/Vehicule';
//import {Service} from './Screen/Service';
//import {Contact} from './Screen/Contact';
//import LINKS, {connexionLink} from './constant';
import {ThemeProvider, createMuiTheme} from '@material-ui/core';

function App() {
  const darkTheme = createMuiTheme({palette: {type: 'dark'}});
  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
          <TopBar />
          <Main />
          <hr className="colored" />
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;

/*
 <BottomTab />
        <Navigation
          {...brandLogo}
          navLinks={LINKS}
          connexionLink={connexionLink}
        />
return (
		<React.Fragment>
			<BrowserRouter>
				<Navigation {...brandLogo} navLinks={LINKS} connexionLink={connexionLink} />
				<Switch>
					<Route exact path={process.env.PUBLIC_URL + '/'} component={Home} />
					<Route path='/vehicules' component={Vehicule} />
					<Route path='/services' component={Service} />
					<Route path='/contact' component={Contact} />
					<Route component={NotFound} />
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	);
	*/
