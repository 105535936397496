import React from 'react';
import content from 'commons/content.json';
import {dpW, dpH} from 'commons';
import {View, TextLabel} from 'component';
import {Image} from 'react-native';

const Branding = () => {
  const tv = content.home.branding;
  return (
    <View marginV={dpH(2)} bg="#101010" paddingV={dpH(2)}>
      <View centerH marginT={dpH(2)}>
        <TextLabel large text={tv.title} align="center" />
        <View height={1} bg={'orange'} width={dpW(15)} marginT={10} />
        <View marginV={dpH(3)} width={dpW(92)} paddingV={dpH(2)}>
          <View height={dpH(25)}>
            <Image
              source={require('assets/brand.png')}
              style={{width: '99%', height: '100%'}}
            />
          </View>
          <TextLabel
            xsmall
            mt={dpH(3)}
            text={tv.description}
            align="center"
            line={4}
          />
        </View>
      </View>
    </View>
  );
};

export default Branding;
