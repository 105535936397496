import React from 'react';
import content from 'commons/content.json';
import {dpW, dpH} from 'commons';
import {View, TextLabel} from 'component';
import {FlatList} from 'react-native';

const DirectMessage = () => {
  const tv = content.home.footer;
  const renderItem = ({item, index}) => {
    return (
      <View
        left={index === 1 || index === 3}
        right={index === 0 || index === 2}
        width={dpW(45)}
        marginV={dpH(2)}
        marginH={dpW(2)}>
        <TextLabel base text={item.name} align="center" color="white" />
        <TextLabel xxsmall text={item.title} align="center" />
        <TextLabel xxsmall text={item.wa} align="center" />
      </View>
    );
  };
  return (
    <View marginV={dpH(2)} bg="#101010" paddingV={dpH(2)}>
      <View centerH marginT={dpH(2)}>
        <TextLabel large text="Direct Whatsapp Message" align="center" />
        <View height={1} bg={'orange'} width={dpW(15)} marginT={dpH(1)} />
      </View>
      <View centerH marginV={dpH(3)}>
        <FlatList
          numColumns={2}
          data={tv.contact}
          renderItem={renderItem}
          keyExtractor={(item, index) => item.id + index.toString()}
        />
      </View>
    </View>
  );
};

export default DirectMessage;
