import React from 'react';
import content from 'commons/content.json';
import {dpW,dpH} from 'commons';
import {View, TextLabel} from 'component';
import {Image, FlatList} from 'react-native';

const tv = content.home.feature;
const FITUR = [
  {
    id: 'voice',
    align: 'right',
    icon: require('assets/voice.png'),
    title: tv.features[0].title,
    desc: tv.features[0].subtitle,
  },
  {
    id: 'remote',
    align: 'left',
    icon: require('assets/remote.png'),
    title: tv.features[1].title,
    desc: tv.features[1].subtitle,
  },
  {
    id: 'cast',
    align: 'right',
    icon: require('assets/cast.png'),
    title: tv.features[2].title,
    desc: tv.features[2].subtitle,
  },
  {
    id: 'multiplayer',
    align: 'left',
    icon: require('assets/hub.png'),
    title: tv.features[3].title,
    desc: tv.features[3].subtitle,
  },
];

const Feature = () => {
  const renderItem = ({item, index}) => {
    return (
      <View width={dpW(47)} marginV={dpH(1)} marginH={10}>
        <View centerH height={dpH(4.5)}>
          <Image source={item.icon} style={{width: dpW(10), height: '100%'}} />
        </View>
        <View centerH>
          <TextLabel base text={item.title} mb={dpH(0.8)} mt={dpH(0.8)} color={'white'} />
          <TextLabel
            xsmall
            text={item.desc}
            line={3}
            align={'center'}
            color={'gray'}
          />
        </View>
      </View>
    );
  };
  return (
    <View marginV={dpH(1)} bg="#101010" paddingT={dpH(3)}>
      <View centerH>
        <TextLabel large text={'Amazing Features'} line={2} align="center" />
        <View height={1} bg={'darkcyan'} width={dpW(15)} marginT={10} />
      </View>
      <View centerH marginV={dpH(5)}>
        <FlatList
          data={FITUR}
          numColumns={2}
          renderItem={renderItem}
          keyExtractor={(item, index) => item.id + index.toString()}
        />
      </View>
    </View>
  );
};

export default Feature;
