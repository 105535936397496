module.exports = {
  get View() {
    return require('./view/').default;
  },
  get TextLabel() {
    return require('./TextLabel').default;
  },
  get BottomTab() {
    return require('./BottomTab').default
  }
};
