import React from 'react';
import {Image, TouchableOpacity, Linking, ScrollView} from 'react-native';
import {dpH} from 'commons';
import {View} from 'component';
import Banner from 'component/Banner';
import Library from './Library';
import Feature from './Feature';
import Branding from './Brand';
import ContactUs from './ContactUs';
import DirectMessage from './Wa';

const Main = () => {
  const onPress = () => {
    Linking.openURL(
      'https://play.google.com/store/apps/details?id=com.smartkaraoke',
      {
        target: '_blank',
        rel: 'noopener',
      },
    );
  };
  return (
    <View flex>
      <ScrollView>
        <TouchableOpacity onPress={onPress}>
          <View height={dpH(24)}>
            <Image
              source={require('assets/smart-tv.jpg')}
              style={{width: '100%', height: '100%', marginVertical: 10}}
            />
          </View>
        </TouchableOpacity>
        <Banner />
        <Feature />
        <Library />
        <Branding />
        <ContactUs />
        <DirectMessage />
      </ScrollView>
    </View>
  );
};
export default Main;
