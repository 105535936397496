import React, {Component} from 'react';
import {BANNER, dpW, dpH} from 'commons';
import {TextLabel, View} from 'component';
import {FlatList, TouchableOpacity} from 'react-native';

const screenWidth = dpW('92%');

export default class Banner extends Component {
  state = {select: 0, scroll: false};
  banner;
  time = null;

  componentDidMount() {
    this.start();
  }
  componentWillUnmount() {
    this.stop();
  }

  start = () => {
    this.time = setInterval(() => {
      this.scrollTo(this.state.select + 1);
    }, 7000);
  };

  stop = () => {
    clearInterval(this.time);
  };

  scrollTo = e => {
    if (e < BANNER.length) {
      if (this.banner) {
        this.banner.scrollToIndex({animated: true, index: e});
        this.setState({select: e});
      }
    } else {
      this.banner.scrollToIndex({animated: true, index: 0});
      this.setState({select: 0});
      this.stop();
    }
  };

  getItemLayout = (data, index) => ({
    length: data.length,
    offset: dpW('97%') * index,
    index,
  });

  onPress = () => {};

  listRef = e => {
    this.banner = e;
  };

  renderItem = ({item, index}) => {
    // console.log('item', item);
    return (
      <BannerItem
        item={item}
        index={index}
        // isFocus={isFocus}
        onPress={this.onPress}
        {...this.props}
      />
    );
  };

  render() {
    return (
      <View flex marginV={dpH(2)}>
        <FlatList
          horizontal
          data={BANNER}
          getItemLayout={this.getItemLayout}
          renderItem={this.renderItem}
          keyExtractor={(item, index) => index.toString()}
          showsHorizontalScrollIndicator={true}
          ref={e => (this.banner = e)}
          {...this.props}
        />
      </View>
    );
  }
}

Banner.defaultProps = {
  data: BANNER,
};

const BannerItem = ({item, onPress, index}) => {
  return (
    <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
      <View
        br={5}
        centerV
        bg={item.color}
        height={dpH(13)}
        width={screenWidth}
        marginH={10}>
        <TextBase
          title={item.title}
          subtitle={item.subtitle}
          desc={item.desc}
          animation="fadeIn"
        />
      </View>
    </TouchableOpacity>
  );
};

const TextBase = ({title, subtitle, desc, show, animation, styles}) => {
  return (
    <View width={dpW(80)} paddingH={20}>
      <TextLabel medium text={title} />
      <TextLabel mt={5} small line={2} text={subtitle} />
      <TextLabel small text={desc} />
    </View>
  );
};
